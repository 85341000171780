const GameSection = () => {
  const gameItems = [
    {
      title: "the-inviter-adabrain-gamejams-2022",
      embedLink:
        "https://itch.io/embed/1721571?border_width=5&bg_color=000000&fg_color=fb922b&link_color=e8908a&border_color=e8908a",
      webLink: "https://adabrain.itch.io/the-inviter-adabrain-gamejams-2022",
      descirption: "The Inviter: AdaBrain GameJams 2022 by AdaBrain.TV",
    },
    {
      title: "call-on-gods",
      embedLink:
        "https://itch.io/embed/1782703?border_width=5&bg_color=f0d26c&fg_color=fb922b&link_color=e8908a&border_color=e8908a",
      webLink: "https://adabrain.itch.io/call-on-gods",
      descirption: "Call On Gods by AdaBrain.TV",
    },
  ];

  const RenderGameItem = () =>
    gameItems.map((item) => (
      <div className="" key={item.title}>
        <iframe
          title={item.title}
          frameBorder="0"
          src={item.embedLink}
          width="auto"
          height="180"
        >
          <a href={item.webLink}>{item.descirption}</a>
        </iframe>
      </div>
    ));

  return (
    <div className="w-100 bg-main py-8">
      <div className="flex justify-center my-14 text-center">
        <h1 className="text-3xl md:text-4xl xl:text-5xl text-header animate-pulse">
          "Play our Game on Itch.io"
        </h1>
      </div>
      <div
        className="
        flex flex-col md:flex-row items-center justify-center
        gap-8 mx-4  my-14 py-10 bg-main 
        border-4 rounded-lg border-text 
      "
      >
        {RenderGameItem()}
      </div>
    </div>
  );
};

export default GameSection;
