import Navbar from "../components/Navbar";
import NavBarMobile from "../components/mobile/NavbarMobile";

const Layout = ({children}) => {
  return (
    <div className="bg-background">
      <Navbar className="hidden lg:block" ></Navbar>
      <NavBarMobile className="lg:hidden" ></NavBarMobile>
      {children}
    </div>
  );
};

export default Layout;
