import {
  FaFacebookF,
  FaTwitter,
  FaInstagramSquare,
  FaYoutube,
  FaDiscord,
  FaTiktok,
  FaItchIo,
} from "react-icons/fa";

const menuItems = [
  { name: "Home", link: "/" },
  { name: "Courses", link: "/courses" },
  { name: "Resources", link: "#" },
  { name: "About", link: "#" },
];

const defaultStyle = "text-text hover:text-hoverText text-xl mr-5 cursor-pointer";

const socialItems = [
  {
    content: <FaFacebookF className={defaultStyle} />,
    link: "https://www.facebook.com/adabrainschool",
  },
  {
    content: <FaTwitter className={defaultStyle} />,
    link: "https://twitter.com/adabraintv",
  },
  {
    content: <FaInstagramSquare className={defaultStyle} />,
    link: "https://www.instagram.com/adabraintv",
  },
  {
    content: <FaYoutube className={defaultStyle} />,
    link: "https://www.youtube.com/c/AdaBraintv",
  },
  {
    content: <FaDiscord className={defaultStyle} />,
    link: "https://discord.gg/jf9nRGRayv",
  },
  {
    content: <FaTiktok className={defaultStyle} />,
    link: "https://www.tiktok.com/@adabraintv",
  },
  {
    content: <FaItchIo className={defaultStyle} />,
    link: "https://adabrain.itch.io/",
  },
];

export { menuItems, socialItems };
