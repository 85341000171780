import { Link } from "react-router-dom";

const RenderTags = (tags) => {
  return tags.map((tag, idx) => (
    <div
      key={idx}
      className="rounded-full h-8 px-3 py-2 outline text-center text-sm bg-green-800 hover:bg-green-700 cursor-pointer animate-pulse"
    >
      {tag}
    </div>
  ));
};

const RenderDescription = (content, courseId) => {
  return content.length >= 140 ? (
    <p className="pt-3 px-4 indent-8 text-ellipsis max-h-sm text-2xl leading-10">
      {content.slice(0, 140)}...
      <Link
        to={`/course/${courseId}`}
        className={`underline pl-3 text-[#C6505A]`}
      >
        See more
      </Link>
    </p>
  ) : (
    <p className="pt-3 px-4 indent-8 text-ellipsis max-h-sm text-2xl leading-10">
      {content}
    </p>
  );
};

const CourseCard = (props) => {
  const { title, slug, description, tags, img } = props;
  return (
    <>
      <div className={`courseItem mb-9 max-w-sm md:max-w-xl bg-main text-text border border-4 border-text] border-b-8 rounded-lg`}>
        <Link to={`/course/${slug}`}>
          <img src={img} alt="Not Loading" />
        </Link>
        <div className="course-detail px-3 py-5 break-words">
          <h1 className="text-[#c6505a] text-lg md:text-xl text-center">
            {title}
          </h1>
          {RenderDescription(description, slug)}
          <hr className="mt-3 mb-3 border-2 border-dashed border-gray-500" />
          <h3 className="text-[#c6505a]">Technologies</h3>
          <div className="tags pt-3 flex flex-row flex-wrap gap-4">
            {RenderTags(tags)}
          </div>
        </div>

        <div className="flex justify-center py-5">
          <Link
            to={`/course/${slug}`}
            className="underline pl-3 text-[#C6505A] "
          >
            <button className="border border-1 border-text border-b-8 bg-outline hover:bg-main text-text  p-3 rounded-xl shadow-md shadow-[#2f142f] cursor-pointer">
              Register
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CourseCard;
