import CourseSection from "../components/CourseSection";
import Layout from "../layouts/Layout";

const AllCoursesPage = () => {
  return (
    <Layout>
      <div className="all-course-page-containe py-28 md:py-8">
        <CourseSection title="All Courses" />
      </div>
    </Layout>
  );
};

export default AllCoursesPage;
