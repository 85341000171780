import courseData from "../assets/data/courses.json";
import CourseCard from "./CourseCard";
import { themeColors } from "../assets/data/theme";

const RenderCourseItems = (courses) => {
  return courses.map((course, idx) => (
    <CourseCard
      key={idx}
      title={course.title}
      slug={course.slug}
      description={course.description}
      tags={course.tags}
      img={course.img}
    />
  ));
};

const CourseSection = (props) => {
  const { title } = props;
  return (
    <div className="bg-outline py-8">
      <div className="course-title flex justify-center mb-14 text-center">
        <h1 className={`text-3xl md:text-4xl xl:text-5xl text-[${themeColors.text}] animate-pulse`}>
          {title}
        </h1>
      </div>
      <div className="course-section flex flex-wrap flex-col md:flex-row justify-around item-center px-5">
        {RenderCourseItems(courseData.courses)}
      </div>
    </div>
  );
};

export default CourseSection;
