import { Link } from "react-router-dom";
import { menuItems, socialItems } from "../assets/data/menu";

const MenuItem = (props) => {
  const { item } = props;
  return <Link
      to={item.link}
    >    
    <div className="button mr-8 text-[#f0d26c] cursor-pointer hover:text-[#bb8548] px-3 py-5">{item.name}</div>
  </Link>
  
};

const RenderMenuItems = () => {
  return menuItems.map((item, idx) => <MenuItem item={item} key={idx} />);
};

const SocialItem = (props) => {
  const { item } = props;
  return <a className="px-1 py-5 w-10 cursor-pointer" href={item.link}>
     <div className="social-item">{item.content}</div>
  </a>
};

const RenderSocialBar = () => {
  return socialItems.map((item, idx) => <SocialItem item={item} key={idx} />);
};

const Navbar = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <div className={`outline outline-2 outline-outline w-screen h-20 py-2 opacity-95 flex justify-center items-center bg-main`}>
        {RenderMenuItems()}
        <div className="socialbar ml-10 flex flex-row max">{RenderSocialBar()}</div>
      </div>
    </div>
  );
};

export default Navbar;
