import CourseSection from "../components/CourseSection";
import CoverSection from "../components/CoverSection";
import GameSection from "../components/GameSection";
import Layout from "../layouts/Layout";

const Divider = (props) => (<div className={props.className}></div>)

const MainPage = () => {
  return (
    <Layout>
      <CoverSection />
      <Divider className="mb-8" />
      <GameSection />
      <Divider className="mb-8" />
      <CourseSection title="Featured Courses" />      
    </Layout>
  );
};

export default MainPage;
