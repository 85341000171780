import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import CoursePage from "./routes/CoursePage";
import NotFoundPage from "./routes/NotFoundPage";
import MainPage from "./routes/MainPage";
import AllCoursesPage from "./routes/AllCoursesPage";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>        
    <BrowserRouter>
    <ScrollToTop />     
      <Routes>        
        <Route path="/" element={<MainPage />} />
        <Route path="/courses" element={<AllCoursesPage />} />
        <Route path="/course/:courseId" element={<CoursePage />} />
        <Route path="*" elemens={<NotFoundPage />} />
      </Routes>      
    </BrowserRouter>    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
