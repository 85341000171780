import { MdMenu } from "react-icons/md";
import { useState } from "react";
import { menuItems, socialItems } from "../../assets/data/menu";
import { Link } from "react-router-dom";

const MenuItem = (props) => {
  const { item } = props;
  return (
    <Link to={item.link}>
      <div className="menu-item p-4 border border-2 border-text bg-main text-text mb-2 hover:bg-[#ee9c5d] hover:text-[#774448]">
        {item.name}
      </div>
    </Link>
  );
};

const RenderMenuItems = () => {
  return menuItems.map((item, idx) => <MenuItem item={item} key={idx} />);
};

const SocialItem = (props) => {
  const { item } = props;
  return (
    <a href={item.link}>
      <div className="social-item">{item.content}</div>
    </a>
  );
};

const RenderSocialBar = () => {
  return socialItems.map((item, idx) => <SocialItem item={item} key={idx} />);
};

const NavBarMobile = (props) => {
  const { className } = props;
  const [isShowMenu, setIsShowMenu] = useState(false);

  return (
    <div className={className}>
      <div className="fixed z-50 navbar-mobile w-screen h-20 bg-main flex flex-row justify-between items-center px-3 outline outline-2 outline-text">
        <div className="logo text-text">
          <Link to="/">AdaBrain.TV</Link>          
        </div>
        <div className="menu">
          <MdMenu
            className={`text-3xl ${
              isShowMenu ? "text-hoverText" : "text-text"
            } cursor-pointer`}
            onClick={() => setIsShowMenu(!isShowMenu)}
          />
        </div>

        <div
          className={`menu ${
            isShowMenu ? "absolute" : "hidden"
          } z-10 w-screen border border-4 border-text p-2 top-20 right-0 bg-main text-text`}
        >
          {RenderMenuItems()}

          <div className="social-bar flex flex-row justify-center my-3">
            {RenderSocialBar()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarMobile;
