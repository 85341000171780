import { Link } from "react-router-dom";

const CoverSection = () => {
  return (
    <div className="bg-[url('assets/image/TheCity.png')] w-full py-24 md:py-10 bg-auto bg-repeat-round">
      <div className="title-text flex justify-center text-3xl md:text-5xl lg:text-7xl animate-bounce text-[#ee9c5d] pt-36 md:pt-24 md:pb-10 z-20">
        <h1 className="">AdaBrain.tv</h1>
      </div>
      <div
        className="subtitle-text mt-5 px-5 py-5 mx-2 lg:px-10 xl:px-72 text-center text-sm md:text-lg lg:text-xl border-text bg-main text-text border border-2 border-text border-b-8 rounded-lg select-all 
      "
      >
        <div className="leading-loose">
          We teach
          <div className="underline underline-offset-7 decoration-4 decoration-[#74a33f] decoration-dashed font-bold">
            Computer Programming, Web Application, and Game Development
          </div>
          in different ways. Remember one thing <strong>"YOU"</strong> are our
          first priority!!!
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <div className="relative hover:translate-y-2 duration-500">
          <Link to="/courses">
            <button className="border border-1 border-text border-b-8 bg-main text-text p-3 rounded-xl shadow-md shadow-[#2f142f] hover:text-header cursor-pointer">
              Get Started
            </button>
          </Link>
          <div className="rounded-full w-5 h-5 bg-[#c65000] absolute -top-2 -right-2 animate-ping"></div>
          <div className="rounded-full w-5 h-5 bg-[#c6505a] absolute -top-2 -right-2"></div>
        </div>
      </div>
    </div>
  );
};

export default CoverSection;
