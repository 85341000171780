import { useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import courseData from "../assets/data/courses.json";

const getTheCourse = (courseId) => {
  return (courseData.courses.filter(c => c.slug === courseId))[0]
}

const CoursePage = () => {
  let params = useParams();
  const content = getTheCourse(params.courseId)  

  return (
    <Layout>
      <div className="course-cover-container flex flex-col lg:flex-row justify-center pt-20 lg:pt-1 xl: xl:px-28 mt-14 pb-14">
        <div className="w-full border border-4 border-b-8 border-text flex justify-start bg-main">
          <div className="course-detail flex flex-col flex-wrap text-text py-5 px-8">
            <h1 className="text-xl md:text-3xl mb-5 text-header">
              {content.title}
            </h1>
            <p
              id="description"
              className="text-md lg:text-2xl leading-10 mb-4 break-words indent-8"
            >
              {content.description}
            </p>
            <div
              id="duration-and-included"
              className="text-xl outline p-3 leading-8 break-words rounded-xl"
            >
              <p className="text-header mb-3">
                <span className="underline">คอร์สสดเริ่ม </span><br />
                {content.periodDate.map(
                  date => <span key={date}>
                    กลุ่ม <span className="text-[#EE9C5D]">{date}</span><br />
                </span>)
                }
              </p>
              <p className="text-[#C6505A] underline">เวลาเรียน</p>
              {content.times.map((time, idx) => <p key={idx}>{time}</p>)}
              <p>จำนวนชั่วโมง {content.totalHours} ชั่วโมง</p>
              <p className="font-bold mt-5 underline text-[#C6505A]">
                คอร์สนี้จะมีอะไรให้บ้าง
              </p>
              <ul className="list-disc list-inside">
                <li>มี Videos บันทึกย้อนหลังให้ทุกครั้ง</li>
                <li>เอกสารประกอบการสอน เนื้อหาภายในคอร์ส</li>
                <li>ได้รับ Certificate หลังจบคอร์สตามเงื่อนไข</li>
                <li>สามารถนำ Project ไปสร้างเป็น Portfolio ได้</li>
                <li>
                  เข้าร่วมกลุ่ม Discord AdaBrain:Exclusive
                  สำหรับถามตอบปัญหาได้ตลอดไป
                </li>
              </ul>
              <p className="text-header mt-5 underline">
                คอร์สนี้เหมาะกับใครบ้าง
              </p>
              <p>ช่วงอายุ: {content.age}</p>
              <p>เป้าหมาย: {content.targets}</p>
            </div>
            <div
              id="price"
              className="border border-2 border-b-8 text-text border-text bg-main text-center text-3xl py-5 my-5 rounded-xl shadow-lg shadow-[#2f142f] hover:text-hoverText hover:border-text :bg-outline hover:-translate-y-2 duration-500"
            >
              <a href={content.shopLink}>
                <div className="animate-puls2e flex flex-row justify-center cursor-pointer">
                  {content.price}
                  <p
                    id="special"
                    className="text-red-500 animate-pulse ordinal"
                  >
                    *
                  </p>
                </div>
                <p>Enroll Now (สมัครเรียน)</p>
              </a>
            </div>
            <div id="conditions">
              <p className="text-xl">
                <span className="text-red-500">*</span>{" "}
                รับส่วนลด และสิทธิพิิเศษต่าง ๆ ได้ผ่าน 
                <a href="https://page.line.me/adabrain">
                  <button className="bg-[#00B901] p-2 rounded-xl ml-3 text-white text-sm border border-b-4 hover:bg-[#0f8e0f]">Line OA: @adabrain</button>
                </a>                
              </p>
            </div>
          </div>
        </div>
        <div className="img md:max-w-4xl max-w-2xl min-w-xl flex align-center border border-4 border-b-8 border-text bg-main">
          <img
            className="object-contain"
            src={content.img}
            alt="course"
          />
        </div>
      </div>
    </Layout>
  );
};

export default CoursePage;
